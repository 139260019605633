import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from 'vuex';
import router from './router'

const packageVersion = process.env.VUE_APP_PACKAGE_VERSION || '0.0.0';
const splits = packageVersion.split('.');
let appVersion;

if (splits[2] === '0') {
    appVersion = splits[0] + '.' + splits[1];
} else {
    appVersion = packageVersion;
}

const store = createStore({
    state() {
        return {
            packageVersion: appVersion
        };
    }
});


createApp(App).use(router).use(store).mount('#app')
