<template>
  <div class="home-container">
    <div class="button-grid">
      <div 
        v-for="(button, index) in buttons" 
        :key="`${this.$router.currentRoute.value.name}-${index}`"
        class="button" 
        @click="goTo(button.route)" 
        :style="{ animationDelay: `${index * 0.1}s`, animationDuration: `${0.4 + index * 0.05}s` }"
      >
        
        <span>{{ button.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    buttons: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    goTo(route) {
      if(route.name === 'site'){

        window.location.href = route.query.url;
      }else{

        this.$router.push(route);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $secondary-color;
}

.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
  background-color: $primary-color;
  color: $secondary-color;
  font-size: 2.5em;
  font-weight: lighter;
  cursor: pointer;
  border-radius: 10px;
  transform: scale(0);
  animation-name: pop;
  animation-fill-mode: forwards;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2980b9;
}

.button span {
  text-align: center;
  padding: 1em;
}

/* Animation de pop */
@keyframes pop {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
