<template>
      <!-- <div v-if="loading" class="loading">
        <div class="content">
            <img class="rotate" src="@/assets/logo-blue-rotation-centered.png"/>
        </div>
        <div class="footer">
          Made by Des Choses Pareilles - Version
        </div>
      </div> -->
      <iframe :src="iframeUrl" @load="onFrameReady()"></iframe>
      <span>{{ iframeUrl }}</span>
  </template>
  
  <script>
  
  export default {
    props: {
    iframeUrl: {
        type: String,
        required: true
        }
    },
  data() {
        return {
            loading: true
        };
    },
    methods: {
        onFrameReady() {
            this.loading = false;
        },
    },
}
  </script>
  
  <style scoped>
  img{
    width: 200px;
  }
  body {
    overflow: hidden;
  }
  
  div.loading{
    position: fixed;
    background-color: white;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  iframe  {
      width: 100vw;
      height: 100vh;
      padding: 0;
      margin: 0;
      border: 0;
      overflow: hidden;
  }
  
  .gelatine {
    animation: gelatine 2.5s infinite;
  }
  @keyframes gelatine {
    from, to { transform: scale(1, 1); }
    80% { transform: scale(1, 1); }
    85% { transform: scale(0.9, 1.1); }
    90% { transform: scale(1.1, 0.9); }
    95% { transform: scale(0.95, 1.05); }
  }
  
  </style>