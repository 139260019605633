<template>
    <!-- <div class="loading"> -->
  <div class="container" @click="goToHome">
    <div class="content">
      <img class="rotate" src="@/assets/logo-blue-rotation-centered.png"/>
    </div>
    <div class="footer">
      Made by Des Choses Pareilles - Version {{ $store.state.packageVersion }}
    </div>
    </div>
  <!-- </div> -->
</template>

<script>
export default {
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    }
  },
}
</script>

<style lang="scss" scoped>

.container{
  background: $secondary-color;
}

img{
  width: 30vw;
}

.rotate {
  animation: rotateAnticlockwise 5s infinite;
}

@keyframes rotateAnticlockwise {
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg); /* Rotation complète en sens antihoraire */
  }
}
</style>

